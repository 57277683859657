import {PppFormData} from '@eon.cz/apollo13-graphql-web';
import Router from 'next/router';
import {useDispatch} from 'react-redux';
import {RootActionType} from '../../lib/rootAction';

import {PageRoute} from '../constants';
import {setActiveStep, setEndForm, setFormData, setPppData, setSuccessStep} from '../reducers/CommonSlice';
import {PppCreateInputForm} from '../types';

type CommonActionType = {
    resetStore: () => void;
    setEndOfForm: () => void;
    resetFormData: () => void;
    setPppData: (data: PppFormData) => void;
    setFormData: (data: Partial<PppCreateInputForm>) => void;
    setActiveStep: (step: number) => void;
    setSuccessStep: (step: number) => void;
};

/**
 * It returns an object with functions that dispatch actions to the Redux store
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();

    return {
        resetStore: () => {
            // Reset store
            Router.push({pathname: PageRoute.VSTUP}).then(() => {
                // Reset store
                dispatch({type: RootActionType.LOGOUT, payload: undefined});
                window.localStorage.clear();
                window.sessionStorage.clear();
            });
        },
        resetFormData: () => {
            dispatch({type: RootActionType.LOGOUT, payload: undefined});
        },
        setEndOfForm: () => {
            dispatch(setEndForm());
        },

        setPppData: (data) => {
            dispatch(setPppData(data));
        },
        setFormData: (data) => {
            dispatch(setFormData(data));
        },
        setActiveStep: (step) => {
            dispatch(setActiveStep(step));
        },
        setSuccessStep(step) {
            dispatch(setSuccessStep(step));
        },
    };
};
