import {gql} from '@apollo/client/core';

export const CommonQueries = {
    gql: {
        odstaveniFormulare: gql`
            query OdstaveniFormulare {
                prvniParalelniPripojeni {
                    odstavka {
                        id
                        aktivni
                        formZakazanHtml
                    }
                }
            }
        `,
        povoleneTypyPriloh: gql`
            query PovoleneTypyPriloh {
                nastaveni {
                    prilohy {
                        povoleneTypyPriloh {
                            id
                            typObjektu
                            typyPriloh
                        }
                    }
                }
            }
        `,
        prilohy: gql`
            query Prilohy($parseId: String!) {
                prvniParalelniPripojeni {
                    formInfo(parseId: $parseId) {
                        prilohy {
                            extId
                            imageId
                            filename
                            mimetype
                            size
                            typ
                            differentiator
                        }
                    }
                }
            }
        `,

        om: gql`
            query NaseptavacOdbernaMista($input: String!, $parseId: String!) {
                adresniMista {
                    fulltextAdresniMista(
                        first: 5
                        filter: {adresa: $input, fulltextFields: [ulice, cisloEvidencni, cisloOrientacni, cisloPopisne, psc, mesto, mistniCast]}
                        parseId: $parseId
                    ) {
                        edges {
                            node {
                                id
                                cisloEvidencni
                                ulice
                                cisloOrientacni
                                cisloPopisne
                                mesto
                                mistniCast
                                psc
                            }
                        }
                    }
                }
            }
        `,
    },
};
