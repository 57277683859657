import {ErrorOutlineOutlined} from '@mui/icons-material';
import {Button, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Div} from '../../styledComponents/Div';

type Props = {
    readonly message: string | undefined;
    readonly refetch: () => void;
};

/**
 * Komponenta pro zobrazení informace při vypnutí formuláře z admin portálu
 *
 * @param {*} {message, refetch}
 */
export const FormOff: FC<Props> = ({message, refetch}) => {
    const {push} = useRouter();
    const {formatMessage} = useIntl();

    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => push('/');
    const handleRefresh = () => refetch();
    return (
        <Div
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                margin: 'auto',
                marginBottom: 5,
                gap: 5,
            }}
        >
            <ErrorOutlineOutlined style={{fontSize: 150}} color="error" />
            <Typography variant="h2" color="inherit">
                <FormattedMessage id="form.off" />
            </Typography>
            <Typography variant="h6" color="inherit" dangerouslySetInnerHTML={{__html: message ?? formatMessage({id: 'form.off.fallback.text'})}} />

            <Div
                sx={{
                    marginTop: 3,
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                <Button
                    size="medium"
                    color="primary"
                    sx={{
                        marginRight: 5,
                    }}
                    variant="contained"
                    onClick={handleRootRedirect}
                >
                    <FormattedMessage id="button.root" />
                </Button>
                <Button size="medium" color="primary" variant="contained" onClick={handleRefresh}>
                    <FormattedMessage id="button.aktualizovat" />
                </Button>
            </Div>
        </Div>
    );
};
