import {CircularProgress, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle} from '@mui/material';
import {memo} from 'react';
import {FormattedMessage} from 'react-intl';

type LoadingDialogProps = DialogProps & {
    /**
     * Description message ID
     */
    readonly description?: string;

    /**
     * Restore focus after dialog is closed
     */
    readonly restoreFocus?: boolean;
};

/**
 * Render loading dialog
 */
export const LoadingDialog = memo(({description, restoreFocus, ...props}: LoadingDialogProps) => (
    <Dialog disableRestoreFocus={!restoreFocus} {...props}>
        <FormattedMessage id="cekejte.prosim">
            {(msg) => (
                <DialogTitle sx={{backgroundColor: 'common.white'}}>
                    {msg}
                    <FormattedMessage id="tecky" />
                </DialogTitle>
            )}
        </FormattedMessage>
        <DialogContent sx={{textAlign: 'center', backgroundColor: 'common.white'}}>
            {description && (
                <DialogContentText>
                    <FormattedMessage id={description} />
                </DialogContentText>
            )}
            <CircularProgress
                sx={{
                    color: 'primary.main',
                }}
            />
        </DialogContent>
    </Dialog>
));
