import getConfig from 'next/config';

// config is NULL when running tests so we must cope with it
const {publicRuntimeConfig} = getConfig();

// reprezentuje prostředí produkce
const ENV_PROD = 'prod';
// reprezentuje prostředí preprodukce
const ENV_PREPROD = 'preprod';
// reprezentuje prostředí dev nebo local
const ENV_DEV = 'development';

// Aktuální hodnota prostředí z runtime nebo dev (local)
const env = publicRuntimeConfig.KUBERNETES_NAMESPACE || ENV_DEV;

const disabledLogin = publicRuntimeConfig.DISABLED_LOGIN === 'true' && !!publicRuntimeConfig.DISABLED_LOGIN;

export const redirectURI = publicRuntimeConfig.SELFCARE_FRONTEND_URL;

/**
 * Utility na prostředí systému
 *
 * Tyto utility potřebují mít KUBERNETES_NAMESPACE v publicRuntimeConfigu v next.config.js!!!
 */
export const EnvironmentUtils = {
    /**
     * Vrátí aktuální hodnotu prostředí (dev, test01, demo, prod, preprod,...)
     */
    getEnv: (): string => env,
    /**
     * Kontroluje zda je prostředí produkce nebo preprodukce (preprodukce má být 1:1 k produkci).
     */
    isProd: (): boolean => env === ENV_PROD,

    /**
     * Kontroluje zda je prostředí produkce nebo preprodukce (preprodukce má být 1:1 k produkci).
     */
    isProdPreprod: (): boolean => EnvironmentUtils.isProd() || env === ENV_PREPROD,

    /**
     * Kontrola povoleni prihlasovani do aplikace
     */
    isLoginDisabled: (): boolean => disabledLogin ?? false,
};
