import {ReactNode} from 'react';

export enum NotificationType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

/**
 * Model for notices
 */
export type NotificationModel = {
    readonly serial: number;
    readonly type?: NotificationType;
    readonly text: ReactNode;
    readonly errorObject?: any;
    readonly autohideTime?: number;
};
