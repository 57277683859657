import {AppBar, Button, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../styledComponents/Div';
import {PaperBlock} from '../layout/PaperBlock';

type Props = {
    readonly error?: Error;
    readonly resetErrorBoundary?: () => void;
};

export const ErrorFallback = ({resetErrorBoundary}: Props) => (
    <Div sx={(theme) => ({padding: `64px ${theme.spacing()}px ${theme.spacing()}px`})}>
        <AppBar sx={(theme) => ({padding: theme.spacing(2), display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'})}>
            <Typography variant="h5" color="inherit">
                <FormattedMessage id="error.page" />
            </Typography>
        </AppBar>

        <PaperBlock titleBlock={''}>
            <Div
                sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Typography variant="h6" color="inherit">
                    <FormattedMessage id="error.http.unexpectedContent1" />
                </Typography>
                <Button sx={{width: 100}} color="primary" variant="contained" disableRipple onClick={resetErrorBoundary}>
                    <FormattedMessage id="button.reset" />
                </Button>
            </Div>
        </PaperBlock>
    </Div>
);
