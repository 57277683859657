import {PppFormData} from '@eon.cz/apollo13-graphql-web';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PppCreateInputForm} from '../types';

export type CommonStore = {
    readonly pppData: PppFormData | undefined;
    readonly activeStep: number;
    readonly formData: Partial<PppCreateInputForm> | undefined;
    readonly formSuccessSteps: {[key: number]: boolean} | undefined;
    readonly endOfForm: boolean;
};

const initialState: CommonStore = {
    pppData: undefined,
    activeStep: 0,
    formData: undefined,
    formSuccessSteps: undefined,
    endOfForm: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPppData: (state: CommonStore, {payload}: PayloadAction<PppFormData>): CommonStore => {
            return {...state, pppData: payload};
        },
        setFormData: (state: CommonStore, {payload}: PayloadAction<Partial<PppCreateInputForm>>): CommonStore => {
            return {...state, formData: {...state.formData, ...payload}};
        },
        setActiveStep: (state: CommonStore, {payload}: PayloadAction<number>): CommonStore => {
            return {...state, activeStep: payload};
        },
        setSuccessStep: (state: CommonStore, {payload}: PayloadAction<number>): CommonStore => {
            return {...state, formSuccessSteps: {...state.formSuccessSteps, [payload]: true}};
        },
        setEndForm: (state: CommonStore): CommonStore => {
            return {...state, endOfForm: true};
        },
    },
});

export const {setPppData, setActiveStep, setFormData, setSuccessStep, setEndForm} = commonSlice.actions;

export default commonSlice.reducer;
