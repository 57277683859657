import {Link, Paper, Typography} from '@mui/material';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {NotificationModel} from '../../../../models/notification/NotificationModel';
import {Pre} from '../../../styledComponents/Pre';
import {Span} from '../../../styledComponents/Span';
import {EnvironmentUtils} from '../../../utils/EnvironmentUtils';

type Props = {
    readonly notification?: NotificationModel;
    readonly moreMessages: number;
};

export const NotificationsMessageComponent = ({notification, moreMessages}: Props) => {
    const [errorShown, setErrorShown] = useState<boolean>(false);
    const handleShowError = () => setErrorShown(true);

    if (notification === undefined) {
        return <span />;
    }

    const canShowErrorObject = !EnvironmentUtils.isProdPreprod();

    return (
        <>
            <Typography data-testid={`notification-message`} color="inherit" sx={{paddingRight: 4}}>
                {notification.text}
                {notification.errorObject && !errorShown && canShowErrorObject && (
                    <span>
                        &nbsp;{' '}
                        <Link
                            onClick={handleShowError}
                            sx={{
                                opacity: 0.8,
                                fontWeight: 'lighter',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                        >
                            <FormattedMessage id="(zdroj)" />
                        </Link>
                    </span>
                )}
                {moreMessages > 0 && (
                    <Span
                        sx={{
                            fontWeight: 'lighter',
                            fontStyle: 'italic',
                            opacity: 0.6,
                        }}
                    >
                        &nbsp;{'+'}
                        {moreMessages}
                    </Span>
                )}
            </Typography>
            {notification.errorObject && errorShown && (
                <Paper>
                    <Pre
                        sx={{
                            padding: 1,
                            color: 'text.primary',
                            overflow: 'auto',
                        }}
                    >
                        {JSON.stringify(notification.errorObject, null, 2)}
                    </Pre>
                </Paper>
            )}
        </>
    );
};
