import {Action, AnyAction, bindActionCreators, Dispatch} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {addNotification, closeNotification, NotificationAddRequest} from '../reducer/NotificationSlice';

export type NotificationsActions = {
    readonly addNotification: (notification: NotificationAddRequest) => Action;
    readonly closeNotification: () => Action;
};

export const useAddNotification = () => {
    const dispatch = useDispatch();

    return {
        addNotification: (notification: NotificationAddRequest) => dispatch(addNotification(notification)),
        closeNotification: () => dispatch(closeNotification()),
    };
};

const NotificationActionsImpl: NotificationsActions = {
    addNotification: (notification: NotificationAddRequest) => addNotification(notification),
    closeNotification: () => closeNotification(),
};

export const NotificationsActionCreator = (dispatch: Dispatch<AnyAction>): NotificationsActions => bindActionCreators({...NotificationActionsImpl}, dispatch);
